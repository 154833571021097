#sidebar {
  border-right: 1px solid $gray-300;
  display: flex;
  flex: 0 0 15rem;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  padding: $top-spacer;
  width: rem-calc(260);

  img {
    margin-bottom: 4rem;
    width: 7rem;
  }

  a {
    color: $danish-shipping-dark-grey;

    &:hover {
      color: $black;
      text-decoration: none;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      a {
        display: inline-block;
        margin: 0.5rem 0;
        padding: 0.25rem 0;
        width: 100%;
      }
    }
  }

  section:last-of-type {
    margin-top: auto;
  }
}
