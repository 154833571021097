.pagination a,
.pagination span.current,
.pagination span.gap {
  background-color: #fff;
  border: 1px solid $danish-shipping-grey;
  border-left-width: 0;
  color: $danish-shipping-dark-grey;
  float: left;
  line-height: 38px;
  padding: 0 14px;
  text-decoration: none;
}

.pagination {
  border-left: 1px solid $danish-shipping-grey;
  float: right;

  span.current {
    background-color: $danish-shipping-darkblue;
    color: #fff;
  }

  a:hover {
    color: $danish-shipping-darkblue;
  }
}
