.sessions form,
.registrations form,
.passwords form,
.login-form {
  padding: 1rem;

  .form-control {
    box-sizing: border-box;
    font-size: 1rem;
    height: auto;
    padding: 0.75rem;
    position: relative;

    &:focus {
      z-index: 2;
    }

    &::placeholder {
      color: $danish-shipping-grey;
    }
  }

  input[type="email"] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: -1px;
  }

  input[type="password"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 0.75rem;
  }

  .inline-btn {
    border-radius: 5%;
    padding: 3rem 1rem;

    &:hover {
      outline: 2px solid $danish-shipping-darkblue;
      outline-offset: -2px;
    }
  }
}

.admin-login {
  max-width: 46rem;
}
