$theme-colors: (
  "primary": $danish-shipping-darkblue,
  "secondary": $danish-shipping-grey,
  "success": $danish-shipping-green,
  "info": $danish-shipping-yellow,
  "warning": $danish-shipping-red,
  "dark": $danish-shipping-dark-grey,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

//Font
$font-size-base: 0.85rem;

@import "bootstrap/scss/bootstrap";

//Modals
$modal-content-border-radius: 0.1rem;

//Buttons
$input-btn-padding-x: 1.5rem;
$btn-font-weight: 900;
$gray-900: $danish-shipping-dark-grey;

nav {
  ol.breadcrumb {
    background-color: inherit;
    padding-left: 0;
  }
}

form:not(.form-inline) {
  max-width: 32rem;
}

.form-control {
  height: initial;
}

body.layout-vacation-employee {
  form:not(.form-inline) {
    max-width: none;
  }
}

.admin-side {
  input[type="date"] {
    height: 32px;
  }
}

//Bootstrap 4 -> 5

a,
.btn-link {
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: underline;
}

:root {
  --bs-link-color: var(--bs-primary);
  --bs-link-hover-color: #000016;
  --bs-font-sans-serif: "nationale";
}

.form-inline {
  align-items: center;
  display: flex;
  flex-flow: row wrap;

  .form-control {
    display: inline-block;
    vertical-align: middle;
    width: auto;
  }

  .form-group {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    margin-bottom: 0;
  }
}

.form-group {
  margin-bottom: 1rem;
}

input.btn-block[type="submit"],
input.btn-block[type="reset"],
input.btn-block[type="button"] {
  width: 100%;
}

.form-text {
  display: block;
}

.btn-outline-danger {
  border-color: var(--bs-red);
  color: var(--bs-red);

  &:hover {
    background-color: var(--bs-red) !important;
    border-color: var(--bs-red) !important;
    color: var(--bs-white) !important;
  }
}

.badge-primary {
  background-color: var(--bs-primary);
  color: var(--bs-white);
}

.btn-danger {
  background-color: var(--bs-red);
  border-color: var(--bs-red);
  color: var(--bs-white);

  &:hover {
    background-color: #c82333 !important;
    border-color: #bd2130 !important;
    color: #fff !important;
  }
}
